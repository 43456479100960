import { defineStore } from "pinia";
import HiRTCSdk from "@/utils/HiRTCSdk";

export const useDefaultDeviceStore = defineStore("defaultDevice", {
  state: () => ({
    hirtcSdk: null,
    isInit: false,

    audioInputList: [],
    audioInput: null,
    audioInputLevel: 50,
    audioOutList: [],
    audioOut: null,
    audioOutLevel: 50,
    videoInputList: [],
    videoInput: null,
    cameraName: [],
  }),
  actions: {
    // 第一步 初始化 HiRTCSdk
    init(options) {
      this.hirtcSdk = new HiRTCSdk(options);
      this.registerUnloadEvent();
      this.audioInput = null;
      this.audioOut = null;
    },

    // 第二步 初始化设备
    initDevice() {
      // 获取用户设备
      this.hirtcSdk.askForPermission(true, true);
      console.log("监听获取设备");
      this.hirtcSdk.addListener("deviceList", this.handleDeviceList);
      console.log("监听获取设备 设备热插拔");
      this.hirtcSdk.addListener("deviceChanged", this.handleDeviceChanged);
      this.hirtcSdk.getDevices();
    },

    // 第三步 处理设备列表
    async handleDeviceList(info) {
      console.log("this.videoInput", this.videoInput);
      console.log("获取设备成功", info);

      // 设置音频输入
      this.audioInputList = info?.audioinput || [];
      if (!this.audioInput) {
        this.audioInput = this.audioInputList[0]?.id || null;
      }

      // 设置音频输出
      this.audioOutList = info?.audiooutput || [];
      if (!this.audioOut) {
        this.audioOut = this.audioOutList[0]?.id || null;
      }

      // 处理视频输入
      if (info?.videoinput) {
        // 先检查所有当前设备是否仍然可用
        let newVideoInputList = [];
        for (const device of info.videoinput) {
          // const canUse = await this.checkVideoDevice(device.id);
          // if (canUse) {
          newVideoInputList.push(device);
          // }
        }

        // 移除不再存在的设备
        this.videoInputList = this.videoInputList.filter((device) => newVideoInputList.some((newDevice) => newDevice.id === device.id));

        // 添加新检测到的设备
        for (const device of newVideoInputList) {
          if (!this.videoInputList.some((oldDevice) => oldDevice.id === device.id)) {
            this.videoInputList.push(device);
          }
        }

        // 更新默认设备
        this.videoInput = this.videoInput ? this.videoInput : this.videoInputList[0]?.id || null;
      } else {
        this.videoInput = [];
      }
    },

    // 检查视频设备是否可以获取流
    async checkVideoDevice(deviceId) {
      const constraints = {
        video: { deviceId: deviceId ? { exact: deviceId } : undefined },
      };
      return navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          // 成功获取流，释放流并返回true
          stream.getTracks().forEach((track) => track.stop());
          return true;
        })
        .catch((error) => {
          // 获取流失败，返回false
          console.error("摄像头检查失败:", deviceId, error);
          return false;
        });
    },

    // 第四步 处理设备变更
    handleDeviceChanged() {
      console.log("设备热插拔，变更设备中....");
      this.hirtcSdk.getDevices();
    },

    // 第五步  销毁监听器
    destroyListeners() {
      if (this.hirtcSdk) {
        this.hirtcSdk.removeListener("deviceList", this.handleDeviceList);
        this.hirtcSdk.removeListener("deviceChanged", this.handleDeviceChanged);
        console.log("监听器已销毁");
      }
    },

    // 注册页面卸载事件
    registerUnloadEvent() {
      window.addEventListener("beforeunload", this.destroyListeners);
    },
  },

  // 持久化配置
  persist: {
    enabled: true,
    strategies: [
      {
        key: "defaultDevice",
        storage: localStorage, // 使用 localStorage 进行持久化
      },
    ],
  },
});

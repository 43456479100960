import * as API from "@/api/api";

const paramType = {
  // 会议及历史列表
  soonStartMeeting: "/meeting/soon/start",
  endMeeting: "/meeting/list/end",
  waitMeeting: "/meeting/list/wait",
  deleteAttendee: "/meeting/delete/attendee",

  // 进入会议
  storeNow: "/meeting/now",
  join: "/meeting/join",
  storeReserve: "/meeting/reserve",
  updateMeetingLeave: "/meeting/leave", // 退出会议
  updateMeetingStop: "/meeting/stop", // 结束会议

  // 组织
  getAllCompanies: "/meeting/companies",
  getControllerUser: "/meeting/controller/users",

  // 更改信息
  joinTime: "/meeting/join/time",
  updateMeeting: "/meeting/update",
  updateMeetingUserId: "/meeting/update/user",
  updateMeetingLayoutId: "/meeting/update/layout",
  updateMeetingLookUserId: "/meeting/update/look",
  updateMeetingLocalNickname: "/meeting/update/nickname",

  meetingBoxList: "/meeting/box/list",
  meetingUserList: "/meeting/user/list",
  meetingBoxListWhereId: "/meeting/box/list/ids",
  meetingUserListWhereId: "/meeting/user/list/ids",

  // 会议流程
  saveMeetingProcess: "/meeting/process/save",
  deleteMeetingProcess: "meeting/process/delete",
  updateMeetingProcess: "meeting/process/update",
  information: "/meeting/information",
  getMeetingProcess: "/meeting/process/list",

  saveMeetingProcessNoMeeting: "/meeting/process/save/no/meeting", // 预约会议的时候的保存会议流程
  getProcess: "/meeting/process/where/id", // 预约会议的时候的编辑会议流程

  // 审核
  approvalList: "/meeting/approval/list",
  approvalAdopt: "/meeting/approval/adopt",
  approvalNotification: "/meeting/approval/notification", // 审核通知

  // 会议录制
  recordStart: "/hirecorder/startMixedStream",
  recordPause: "/hirecorder/pauseMixedStream",
  recordResume: "/hirecorder/resumeMixedStream",
  recordStop: "/hirecorder/stopMixedStream",
};

// 发送 GET 请求的函数
export const meetingGet = (key, param) => {
  const url = paramType[key];
  return API.get(url, param);
};

// 发送 POST 请求的函数
export const meetingPost = (key, param) => {
  const url = paramType[key];
  return API.post(url, param);
};

import { indexPost } from "@/api/modules";
import { useVersionStore } from "@/store/useVersionStore";
export default {
  data() {
    return {
      dialogStatus: false,
      version: {
        version: "",
        download_url: "",
        update_at: ""
      }
    };
  },
  mounted() {
    const versionStore = useVersionStore();
    versionStore.version = "1.0.1";
    if (window.electronAPI) {
      indexPost("version").then(res => {
        if (res.status) {
          // 将版本号转为整数，移除点并转换为数字
          const currentVersion = parseInt(versionStore.version.replace(/\./g, ""));
          const serverVersion = parseInt(res.data.version.replace(/\./g, ""));
          if (serverVersion > currentVersion) {
            // 如果服务器版本大于当前版本，提示更新
            this.version = {
              version: res.data.version,
              download_url: res.data.download_url,
              update_at: res.data.update_at
            };
            this.dialogStatus = true;
          } else {
            // 如果版本号相等或更高，不提示
            this.dialogStatus = false;
          }
        }
      });
    }
  },
  methods: {
    goDownload() {
      if (window.electronAPI) {
        window.electronAPI.goDownload(this.version.download_url);
      }
    }
  }
};